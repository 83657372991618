import { useCombinedRef } from "@adv-libs/utils";
import clsx from "clsx";
import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ReactDOM from "react-dom";
import cm from "../utils/cm";
import cp from "../utils/cp";
import TabsContext from "./TabsContext";

export interface TabsProps {
  fullHeight?: boolean;
  flushContent?: boolean;
  onClick?: (e) => any;
  onMouseDown?: (e) => any;
  listAnchor?: boolean;
  listAnchorRef?: any;
}

const DEFAULT_TAB_WIDTH = 140;

const Tabs = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<TabsProps>
>(
  (
    {
      children,
      fullHeight,
      flushContent,
      listAnchor,
      listAnchorRef,
      ...restProps
    },
    ref
  ) => {
    const tabsRef = useRef<HTMLDivElement>();
    const markerRef = useRef<HTMLDivElement>();

    const filterChild = useMemo<any>(() => {
      return React.Children.toArray(children).filter(
        (item: any) => item && item.props
      )[0];
    }, []);

    const [activeTab, setActiveTab] = useState(
      filterChild && filterChild.props?.id
    );

    const onClickTabItem = useCallback((id: string) => {
      setActiveTab(id);
    }, []);

    const tabsContextValue = useMemo(() => {
      return {
        onTabClick: onClickTabItem,
        activeTab: activeTab,
        isContent: false,
        flushContent,
      };
    }, [onClickTabItem, activeTab]);

    const tabsContentContextValue = useMemo(() => {
      return {
        onTabClick: onClickTabItem,
        fullHeight: fullHeight,
        activeTab: activeTab,
        isContent: true,
        flushContent,
      };
    }, [onClickTabItem, activeTab]);

    useLayoutEffect(() => {
      const root = listAnchor ? listAnchorRef : tabsRef.current;
      if (!activeTab && root) {
        const child = root.querySelector(".r365-tab");
        if (child) {
          const id = child.getAttribute("data-id");
          setActiveTab(id);
        }
      }
    }, [listAnchorRef]);

    useLayoutEffect(() => {
      if (!listAnchor) {
        if (activeTab) {
          const $marker = markerRef.current;
          const $activeTab = tabsRef.current.querySelector<HTMLLIElement>(
            `.r365-tab[data-id="${activeTab}"]`
          );
          const offsetLeft = $activeTab.offsetLeft;
          const tabWidth = $activeTab.clientWidth;
          const scaleX = tabWidth / DEFAULT_TAB_WIDTH;
          $marker.style[
            "transform"
          ] = `translate(${offsetLeft}px, 0px) scaleX(${scaleX})`;
        }
      }
    }, [activeTab]);

    useLayoutEffect(() => {
      if (listAnchor && listAnchorRef) {
        const $tabs = Array.from(
          listAnchorRef.querySelectorAll(".r365-tab")
        ) as HTMLElement[];

        if ($tabs.length === 1) {
          $tabs[0].style["display"] = "none";
        } else {
          $tabs.forEach(($tab) => ($tab.style["display"] = "block"));
        }
      }
    }, [listAnchor, listAnchorRef]);

    return (
      <div
        ref={useCombinedRef(ref, tabsRef)}
        className={clsx(
          cp("tabs"),
          cm(cp("tabs"), {
            "full-height": fullHeight,
          })
        )}
        {...restProps}
      >
        {listAnchor && listAnchorRef ? (
          ReactDOM.createPortal(
            <div className={cp("tabs__header")}>
              <ol className={cp("tabs__list")}>
                <TabsContext.Provider value={tabsContextValue}>
                  {children}
                </TabsContext.Provider>
              </ol>
            </div>,
            listAnchorRef
          )
        ) : !listAnchor ? (
          <div className={cp("tabs__header")}>
            <ol className={cp("tabs__list")}>
              <TabsContext.Provider value={tabsContextValue}>
                {children}
              </TabsContext.Provider>
            </ol>
            <div ref={markerRef} className={cp("tabs__marker")}></div>
          </div>
        ) : null}

        <div className={cp("tabs__content")}>
          <TabsContext.Provider value={tabsContentContextValue}>
            {children}
          </TabsContext.Provider>
        </div>
      </div>
    );
  }
);

export default Tabs;
